import { render, staticRenderFns } from "./AllDiscountView.vue?vue&type=template&id=9d4f7eb8&scoped=true&"
import script from "./AllDiscountView.vue?vue&type=script&lang=js&"
export * from "./AllDiscountView.vue?vue&type=script&lang=js&"
import style0 from "./AllDiscountView.vue?vue&type=style&index=0&id=9d4f7eb8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d4f7eb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VDataTable,VIcon,VList,VListItem,VListItemTitle,VMenu,VProgressLinear,VTab,VTabItem,VTabs,VTextField})
