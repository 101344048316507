<script >
import {mapGetters} from "vuex";
import  voucherbg1 from '@/assets/voucher/voucher-bg-1.svg'
import dayjs from "dayjs";
import  voucherbg4 from '@/assets/voucher/voucher-bg-4.svg'
import {assignVoucherToTraveller, deleteVoucher} from "@/services/api/APIService";

import  voucherbg8 from '@/assets/voucher/voucher-bg-8.svg'

export default {
  name: "VoucherCard",
  props: {
    voucher: Object,
    color: {
      type: [String],
      default: "#004AAD",
    },
  },
  data(){
    return {
      showFront: true,
      transitioning : false,
      assignedToTravellerEmail:null,
      sendVoucherLoading: false,
    }
  },
  computed: {
    ...mapGetters("companyProfile",["companyData"]),
    generateBackgroundImage() {
      if (this.voucher.assignedToTraveller && this.voucher.redeemed) {
        return voucherbg1;
      }
      else if (this.voucher.assignedToTraveller && !this.voucher.redeemed) {
        return voucherbg8;
      }
      else{
        return voucherbg4;
      }
    },
  },
  methods: {
    flipCard() {
         this.showFront = !this.showFront;
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
    },
    formatDate(date){
      return dayjs(date).format("LL")
    },
    sendVoucherToTraveller(){
      this.sendVoucherLoading = true;
      let data = {}
      data.assignedToTravelerEmail = this.assignedToTravellerEmail;
      data.code = this.voucher.code
      data.id= this.voucher.id
      assignVoucherToTraveller(data).then(res => {
        if(res.data.status === "successful"){
          this.$emit("getAllVouchers")
        }
      }).catch(error => {
        this.$displaySnackbar({message: error.message,success:false})
      })
          .finally(() => this.sendVoucherLoading = false)
    },
    async deleteAVoucher(){
      let data = {}
      data.id= this.voucher.id
      await deleteVoucher(data).then(res => {
        if(res.data.status === "successful"){
          this.$emit("getAllVouchers")
        }
      }).catch(error => {
        this.$displaySnackbar({message: error.message,success:false})
      })
    }
  },
  created() {

  }
}
</script>

<template>
  <div class="parent-card" :style="{width: showFront ? '400px' : '500px', height: showFront ? '150px' : '200px'}">
  <transition name="flip">
  <v-card class="tw-flex tw-flex-col front card tw-items-center tw-justify-center"
       key="front" @dblclick="flipCard"  v-if="showFront" style="z-index: 0"
          :style="{width: showFront ? '400px' : '500px',
          height: showFront ? '150px' : '200px', backgroundImage: 'url(' + generateBackgroundImage + ')'}">
   <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-pt-3 tw-pl-2">
     <h6 class="voucher-title tw-mt-4">{{voucher.name}}</h6>
     <v-icon color="var(--brown)" class="tw-cursor-pointer" @click="deleteAVoucher">mdi-delete-outline</v-icon>
   </div>
    <div class="tw-flex tw-flex-row tw-items-center">
      <h6 class="currency" v-if="voucher.type === 'FIXED'">₦</h6>
      <h6 class="amount">{{formatNumber(voucher.amount)}}</h6>
      <h6 class="currency" v-if="voucher.type === 'PERCENTAGE'">%</h6>
    </div>
    <div class="code">{{voucher.code}}</div>
    <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-mb-2">
      <h6 class="validity">Till : {{formatDate(voucher.expiryDate)}}</h6>
      <h6 class="validity">{{voucher.assignedToTraveller ? 'Assigned' : 'Available'}}</h6>
    </div>
  </v-card>
  <v-card class="tw-flex tw-flex-col back tw-w-full card"
       key="back" @dblclick="flipCard" style="z-index: 1" v-else
          :style="{width: showFront && voucher.assignedToTraveller? '400px' : '500px', height: showFront && voucher.assignedToTraveller ? '150px' : '200px', backgroundImage: 'url(' + generateBackgroundImage + ')'}">
    <div class="tw-flex tw-w-full tw-flex-col " v-if="!voucher.assignedToTraveller">
      <label class="customer-email">Customer email</label>
      <v-text-field type="email" :color="color" rules="required"
                    prepend-inner-icon="mdi-email" placeholder="customer@example.com"
                    solo hide-details v-model="assignedToTravellerEmail"></v-text-field>
      <v-btn class="send-btn tw-mt-2" :style="{backgroundColor: color}"
             @click="sendVoucherToTraveller" :loading="sendVoucherLoading">Send</v-btn>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col " v-else>
      <div class="tw-flex tw-w-full tw-flex-row">
        <h6 class="text-header">Issued To: </h6><h6 class="text-desc">{{voucher.travellerEmail}}</h6>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-my-3">
        <h6 class="text-header">Valid till: </h6><h6 class="text-desc">{{formatDate(voucher.expiryDate)}}</h6>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row">
        <h6 class="text-header">Booking: </h6><h6 class="text-desc">{{voucher.usedBy}}</h6>
      </div>
    </div>
  </v-card>
  </transition>
  </div>
</template>

<style scoped lang="scss">
.parent-card{
  position: relative;
  margin: 10px 10px;
  @media screen and(max-width: 800px){
    margin: 10px 0;
  }
}
.card{
  position: absolute;
  //background-image: url("../../assets/voucher/voucher-bg-4.svg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 1.8rem;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 150px;
  }
  animation: ease-in-out;
  animation-duration: 1s;
  animation-direction: normal;
}
.front{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: "Bebas Neue", serif;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--brown);
}

.voucher-title{
  font-family: "Bebas Neue", serif;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--black);
}

.currency{
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--brown);
}
.amount{
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 10px;
  color: var(--brown);
}
.validity{
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-variation-settings:
      "ROND" 5;
  color: var(--brown);
}
.customer-email{
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--brown);
}
.send-btn{
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: capitalize;
  color: var(--white);
  //background-color: var(--black) !important;

}

.text-header{
  width: 50%;
  font-family: "Doto", serif;;
  font-size: 16px;
  font-weight: bold;
  font-variation-settings:
      "ROND" 0;
  color: var(--black);
}
.text-desc{
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: var(--black);
}

.flip-enter-active,
.flip-leave-active {
  transition: transform 2s;
  backface-visibility: hidden !important;
}

.flip-enter-from {
  transform: rotateY(360deg);
}

.flip-leave-to {
  transform: rotateY(360deg);
}

.flip-back-enter-active,
.flip-back-leave-active {
  transition: transform 2s;
  backface-visibility: hidden !important;
}

.flip-back-enter-from {
  transform: rotateY(-360deg);
}

.flip-back-leave-to {
  transform: rotateY(-360deg);
}
.unassigned{
  z-index: 0;
}
.assigned{
  z-index: 1;
}
.code{
  width: max-content;
  height: max-content;
  background-color: var(--brown);
  color: var(--white);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 0 1rem;
}
</style>