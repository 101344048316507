<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
    <icons name="arrow-back" class="tw-cursor-pointer" @click="$router.back()" />
  </div>

  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <v-tabs
          fixed-tabs
          align-with-title
          v-model="tab"
          class="tw-pb-5 lg:tw-pb-0"
          :slider-color="color"
          :color="color">
        <v-tab class="tw-justify-start switch-header tw-p-1">
          <v-icon class="tw-mr-2">mdi-percent</v-icon>
          Discounts
        </v-tab>
        <v-tab class="tw-justify-start switch-header tw-p-1">
          <v-icon class="tw-mr-2">mdi-ticket-percent</v-icon>
          Vouchers
        </v-tab>
        <v-tab-item class="tw-flex tw-w-full tw-flex-col tw-bg-white tw-rounded-md tw-px-5">

          <div class="tw-flex tw-w-full tw-flex-row tw-justify-end tw-items-center">
            <v-btn @click="$router.push({name: 'CreateDiscount'})" class="create-btn" :style="{backgroundColor: color}">Create Discount <v-icon>mdi-plus</v-icon></v-btn>
            <div class="tw-flex tw-w-4/12 tw-ml-5">
              <v-text-field
                  placeholder="Search"
                  class="p-0" solo
                  hide-details
                  color="#FDFFFC"
                  v-model="search">
                <template #prepend-inner>
                  <v-icon :color="color" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="color"> mdi-filter-variant </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <v-data-table
              :headers="headers"
              :mobile-breakpoint="0"
              :search="search"
              item-key="id"
              :single-select="false"
              height="available"
              :hide-default-footer="discounts.length <= 7"
              :items="discounts"
              :no-data-text="not_found"
              class="mt-4 mx-1 table"
              :loading="loading"


          >
            <template #header.name>
              <span class="status">Title</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span class="td-style">{{ item.name }}</span>
            </template>

            <template #header.type>
              <span class="status">Type</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span class="td-style">{{ item.type }}</span>
            </template>
            <template #header.unit>
              <span class="status">Unit (s)</span>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <span class="td-style">{{item.unit }}</span>
            </template>
            <template #header.unitAmount>
              <span class="status">Unit Amount</span>
            </template>
            <template v-slot:[`item.unitAmount`]="{ item }">
              <span class="td-style">{{item.unitAmount }}</span>
            </template>
            <template #header.startDate>
              <span class="status">Start Date</span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <span class="td-style">{{getDate(item.startDate) }}</span>
            </template>
            <template #header.endDate>
              <span class="status">End Date</span>
            </template>
            <template v-slot:[`item.endDate`]="{ item }">
              <span class="td-style">{{getDate(item.endDate) }}</span>
            </template>
            <template #header.additionalIncentive>
              <span class="status">Additional Incentive</span>
            </template>
            <template v-slot:[`item.additionalIncentive`]="{ item }">
              <span class="td-style">{{item.additionalIncentive }}</span>
            </template>
            <template #header.additionalIncentiveUnitAmount>
              <span class="status">AI Unit Amount</span>
            </template>
            <template v-slot:[`item.additionalIncentiveUnitAmount`]="{ item }">
              <span class="td-style">{{item.additionalIncentiveUnitAmount }}</span>
            </template>
            <template #header.totalBookings>
              <span class="status">Total Bookings</span>
            </template>
            <template v-slot:[`item.totalBookings`]="{ item }">
              <span class="td-style">{{item.totalBookings }}</span>
            </template>
            <template #header.createdBy>
              <span class="status">Created By</span>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
              <span class="td-style">{{item.discountTag === 'NOMADIAN'? item.createdByPaddlerFirstName +" "+item.createdByPaddlerLastName+" (Nomadian)" : item.createdByTransportCompanyName}}</span>
            </template>
            <template #header.createdAt>
              <span class="status">Date Created</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span class="td-style">{{getDate(item.createdAt) }}</span>
            </template>
            <template #header.status>
              <span class="status">Status</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status.toLowerCase() === 'active'" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  Active
                </span>
              </v-chip>
              <v-chip v-if="item.status.toLowerCase() === 'inactive'" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Inactive
                </span>
              </v-chip>
              <v-chip v-if="item.status.toLowerCase() === 'pending'" color="rgba(147, 147, 147, 0.41)">
                <span class="td-style" style="color: #4B4B4B">
                  Pending
                </span>
              </v-chip>
            </template>
            <template #header.dateCreated>
              <span class="status">Date Created</span>
            </template>
            <template v-slot:[`item.dateCreated`]="{ item }">
              <span class="td-style">{{getDate(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.actions`] ="{item}">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="color">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" v-if="item.status === 'ACTIVE'" @click="deactivateActiveDiscount(item)">
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                      Deactivate
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item class="tw-flex tw-w-full  tw-bg-white tw-pb-5  voucher-container tw-p-10 tw-items-start">
          <div class="tw-flex tw-w-full tw-flex-row tw-justify-end tw-items-center">
            <v-btn @click="showVoucherModal" class="create-btn" :style="{backgroundColor: color}">Create Voucher <v-icon>mdi-plus</v-icon></v-btn>
            <div class="tw-flex tw-w-4/12 tw-ml-5">
              <v-text-field
                  placeholder="Search"
                  class="p-0" solo
                  hide-details
                  color="#FDFFFC"
                  v-model="search">
                <template #prepend-inner>
                  <v-icon :color="color" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="color"> mdi-filter-variant </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <v-progress-linear v-if="voucherLoading" :color="color"/>
          <voucher-card v-for="(voucher, index) in filteredVouchers" :key="index" :voucher="voucher" @getAllVouchers="getAllVouchers" />

        </v-tab-item>
      </v-tabs>
      <modal section="create-voucher"
             :dialog="voucherModal"
             @close="showVoucherModal"
             :voucher-detail="voucherDetail"
             :color="color"
             @getVouchers="getAllVouchers">

      </modal>


    </div>
  </div>
</div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import dayjs from "dayjs";
import {allDiscounts, deactivateDiscount, getVouchers} from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal.vue";
import VoucherCard from "@/views/marketing_dept/promotion/VoucherCard.vue";
export default {
  name: "AllDiscountView",
  components: { Icons,Modal,VoucherCard},
  data(){
    return{
      color: "#004AAD",
      search : "",
      discounts:[],
      not_found : "Discount will show here",
      headers: [
        { text: "", value: "name" },
        { text: "", value: "type" },
        { text: "", value: "unit" },
        { text: "", value: "unitAmount" },
        { text: "", value: "startDate" },
        { text: "", value: "endDate" },
        { text: "", value: "additionalIncentive" },
        { text: "", value: "additionalIncentiveUnitAmount" },
        { text: "", value: "totalBookings" },
        { text: "", value: "createdBy" },
        {text: "",value: "createdAt"},
        { text: "", value: "status" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      transportCompanyId:null,
      selectedPost:{},
      tab:null,
      vouchers: [],
      filteredVouchers: [],
      page:0,
      pageSize:100,
      voucherModal: false,
      voucherDetail: {},
      voucherLoading: false,

    }
  },
  watch: {
    search : {
      handler(val){
        if(val) {
          this.filteredVouchers = this.vouchers.filter(voucher => voucher.code.toLowerCase().includes(val.toLowerCase()));
        }
        else {
          this.filteredVouchers = this.vouchers
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods:{
    showVoucherModal(){
      this.voucherModal = !this.voucherModal;
    },
    async getAllVouchers(){
      this.voucherLoading = true;
      let data = {}
      data.page = this.page;
      data.pageSize = this.pageSize;
      await getVouchers(data).then(res => {
        this.vouchers = res.data;
        this.vouchers.sort(function (a, b) {
          return (
              dayjs(b.expiryDate).toDate() -
              dayjs(a.expiryDate).toDate()
          );
        });
        this.filteredVouchers = this.vouchers;
        this.voucherLoading = false;
      }).finally(() => this.voucherLoading = false);
    },
    handleModal(item){
      if (item) {
        this.selectedPost = item
      }
      this.showModal = !this.showModal
    },

   async getAllDiscounts(){
      this.loading = true
      await allDiscounts().then(res =>{
        this.discounts = res.data
        this.discounts.sort(function (a, b) {
          return dayjs(b.createdAt) - dayjs(a.createdAt);
        });
        this.discounts.forEach((post, index) => (post.sno = index + 1));
        this.loading = false
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    async deactivateActiveDiscount(item){
      let data = {}
      data.id = item.id
      data.status = 'INACTIVE'
      await deactivateDiscount(data).then(() =>{
        this.getAllDiscounts()
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    routeToEditPost(item){
      // sessionStorage.setItem("post", JSON.stringify(item))
      this.$store.dispatch("marketing/setSelectedBlogPost",item)
      this.$router.push({name: 'EditBlogPost',params:{title: item.postTitle}})
    },

    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },

  },

  async created() {
    await this.getAllDiscounts()
    await this.getAllVouchers();
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  max-height: available;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 100px;
  width: 100px;
  border-radius: 10px;
  object-fit: contain;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
.create-btn {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  text-transform: capitalize;
}
.voucher-container {
  width: 100%;
  min-height: 50vh;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>